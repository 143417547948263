const SELECTOR_OFF_CANVAS_MENU = '.anw-offcanvas-2021-collapse';
const SELECTOR_STICKY_BAR = '[data-sticky-contact-detail]';
const CSS_CLASS_PREVENT_STICKY_BAR = 'prevented';

const preventStickyBar = function () {
  const offCanvasMenu: HTMLElement | null = document.querySelector(SELECTOR_OFF_CANVAS_MENU);
  const stickyBar: HTMLElement | null = document.querySelector(SELECTOR_STICKY_BAR);

  if (offCanvasMenu !== null && stickyBar !== null) {
    const onShow = () => {
      stickyBar.classList.add(CSS_CLASS_PREVENT_STICKY_BAR);
    };

    const onHide = () => {
      stickyBar.classList.remove(CSS_CLASS_PREVENT_STICKY_BAR);
    };

    offCanvasMenu.addEventListener('show.bs.collapse', onShow);
    offCanvasMenu.addEventListener('hide.bs.collapse', onHide);
  }
};

export default preventStickyBar;
